<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @edit-button="editProduct"
    @add-button="createProduct"
  >
    <v-row v-if="showedProviderId" class="table-btns">
      <div class="ml-7">Информация по филиалам :</div>
      <v-btn
        v-for="provider in currentProviders"
        :key="provider.id"
        :color="provider.id === showedProviderId ? '#5d4bd0' : ''"
        :dark="provider.id === showedProviderId"
        class="ma-2"
        small
        @click="showedProviderId = provider.id"
        >{{ provider.name }}</v-btn
      >
    </v-row>
    <v-simple-table v-if="showedProviderId" class="table-providers">
      <thead>
        <tr class="table-header">
          <th>Филиал</th>
          <th>Остаток</th>
          <th>Цена</th>
          <th>Цена скидки</th>
          <th>Скидка в %</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="departmentObj in input.pricesList.filter(
            (item) => item.providerId === showedProviderId
          )"
          :key="departmentObj.departmentId"
        >
          <td>{{ departmentObj.departmentName }}</td>
          <td>{{ departmentObj.balance }}</td>
          <td>{{ departmentObj.price }}</td>
          <td>{{ departmentObj.discountPrice || 0 }}</td>
          <td>{{ departmentObj.discountPercent || 0 }}%</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="mt-2">
      <v-col cols="12">
        Подробно заполните указанные ниже поля, если в списке отсутствует
        необходимое значение обратитесь в службу поддержки. Поле отмеченное
        <v-icon color="error" x-small>mdi-asterisk</v-icon> является
        обязаетельным для заполнения.
      </v-col>
    </v-row>
    <div class="main">
      <div class="main_data">
        <span class="main_text">Название товара :</span>
        <v-text-field
          v-model="input.name"
          dense
          solo
          flat
          :rules="[$validate.required]"
          class="text-input"
        >
          <template v-slot:append-outer>
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
          </template>
        </v-text-field>
        <span class="main_text">GUId :</span>
        <v-text-field
          v-if="mode === 'Изменить'"
          v-model="input.guid"
          dense
          solo
          flat
          :rules="[$validate.required]"
          class="text-input"
        >
          <template v-slot:append-outer>
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
          </template>
        </v-text-field>
        <span class="main_text">Категория :</span>
        <v-autocomplete
          v-model="input.categoryIds"
          :items="CATEGORY"
          item-text="name"
          item-value="id"
          multiple
          chips
          dense
          solo
          flat
          @blur="setFeatures()"
        >
          <template #selection="{ item }">
            <v-chip color="#e93e7b " dark class="mt-1 pr-1">
              {{ item.name }}
              <v-icon large right @click="deleteChip(item, input.categoryIds)">
                mdi-close-circle
              </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        <span class="main_text">Описание :</span>
        <v-textarea
          v-model="input.description"
          dense
          solo
          flat
          auto-grow
          rows="3"
          row-height="15"
        >
          <template v-slot:append-outer>
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
          </template>
        </v-textarea>
        <span class="main_text">Краткое описание :</span>
        <v-textarea
          v-model="input.shortDescription"
          dense
          solo
          flat
          auto-grow
          rows="3"
          row-height="15"
        ></v-textarea>
        <div class="d-flex align-centeer">
          <span class="main_text mr-5 mt-2"
            >Ед. измерения (Шаг для добавления в корзину) :</span
          >
          <v-select
            v-model="input.measureId"
            :items="MEASURE.allMeasure"
            item-value="id"
            item-text="description"
            menu-props="offsetY"
            :rules="[$validate.required]"
            dense
            solo
            flat
            hide-details
            class="text-input"
          >
            <template v-slot:append-outer>
              <v-icon color="error" x-small>mdi-asterisk</v-icon>
            </template>
          </v-select>
        </div>
        <!-- блок со штрихкодами -->
        <span class="main_text mt-6">Штрихкод:</span>
        <div class="barcode">
          <div class="barcode_item">
            <v-text-field
              v-for="(barc, i) in input.barcodes"
              :key="barc.id"
              v-model="input.barcodes[i]"
              :rules="[$validate.required, $validate.float]"
              type="number"
              dense
              hide-details
              class="input-close"
            >
              <v-btn slot="append" color="white" icon @click="deleteBarcode(i)">
                <v-icon x-large>mdi-close-circle</v-icon>
              </v-btn>
            </v-text-field>
          </div>
          <v-btn text @click="addBarcode()"
            ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon
            >Добавить штрих-код
          </v-btn>
        </div>

        <span v-if="mode === 'Добавить'" class="main_text mt-6"
          >Поставщик:</span
        >
        <v-select
          v-if="mode === 'Добавить'"
          v-model="input.providerId"
          :items="PROVIDERS.allProviders"
          item-value="id"
          item-text="name"
          menu-props="offsetY"
          :rules="[$validate.required]"
          class="text-input"
        >
          <template v-slot:append-outer>
            <v-icon color="error" x-small>mdi-asterisk</v-icon>
          </template>
        </v-select>
      </div>

      <div class="main_images">
        <input
          ref="imgInput"
          type="file"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          multiple
          @change="setImage($event.target.files)"
        />
        <div class="conteiner">
          <!-- вертикальные изображения -->
          <div class="images_small">
            <div class="images_vertical">
              <div
                v-for="(image, index) in input.images"
                :key="index"
                :class="{
                  images_small_img: true,
                  selected: bigImg.id === image.id,
                }"
                class="text-center"
              >
                <img
                  :src="image.fileUrl"
                  class="thumbnail"
                  @click="bigImg = image"
                />
              </div>
            </div>
          </div>

          <div v-if="bigImg" class="image-box">
            <v-img
              max-height="100%"
              max-width="100%"
              style="margin: auto; position: relative"
              contain
              :src="bigImg.fileUrl"
            />
            <v-btn
              icon
              color="error"
              class="image-box_btn"
              absolute
              @click="deleteImg(bigImg)"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </div>
        </div>
        <div class="main_img-btn">
          <v-btn icon large @click="$refs.imgInput.click()">
            <v-icon color="#5d4bd0">mdi-pencil-circle</v-icon>
          </v-btn>
        </div>

        <div style="text-align: center">
          Размер загружаемого файла не должен превышать 500kb
        </div>
        <div style="text-align: center">
          Название файла не должно содержать спец символы
        </div>
      </div>
    </div>

    <v-row>
      <v-col cols="6" class="pb-0">
        <div class="d-flex align-center">
          <span class="main_text mr-2">Скрыть продукт</span>
          <v-checkbox v-model="input.hidden" color="#e93e7b"> </v-checkbox>
        </div>
        <p class="main_hint">
          при выставлении галочки продукт не отображается в продаже
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pt-0">
        <div class="d-flex align-center">
          <span class="main_text mr-2">Специальный продукт</span>
          <v-checkbox v-model="input.highlightInCategory" color="#e93e7b">
          </v-checkbox>
        </div>
        <p class="main_hint">
          при выставлении галочки продукт будет выделен особым цветом на витрине
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="barcode">
        <div class="mr-5">
          <span class="main_text mr-2">Ограничение по заказу товара</span>
          <p class="main_hint ma-0">
            клиент не сможет купить, больше установленного кол-ва
          </p>
        </div>

        <v-text-field
          v-model.number="input.limit"
          type="number"
          dense
          hide-details
          class="input-close"
        >
          <v-btn slot="append" color="white" icon @click="deleteLimit = true">
            <v-icon x-large>mdi-close-circle</v-icon>
          </v-btn>
        </v-text-field>

        <v-checkbox
          v-if="input.limit"
          v-model="deleteLimit"
          label="Удалить ограничение"
          hide-details
          color="#e93e7b"
          class="ma-2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="title">Общие характеристики</span>
      </v-col>
      <v-col cols="12">
        <div class="feature">
          <div
            v-for="item in categoryFeatures"
            :key="item.featureName"
            class="feature_box"
          >
            <span class="feature_text">{{ item.featureName }}</span>
            <div class="feature_under" />
            <v-autocomplete
              v-model="productFeatures[item.featureName]"
              :items="item.featureValue"
              item-text="value"
              item-value="valueId"
              no-data-text="Ничего не найдено"
              clearable
              multiple
              hide-details
              class="feature_select"
            >
              <template v-slot:append>
                <v-icon color="#51C8F0">mdi-menu-down</v-icon>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </v-col>
    </v-row>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      input: {},
      bigImg: null,
      productFeatures: {},
      departments: [],
      fileInputs: [],
      deleteImgs: [],
      categoryId: [],
      currentProviders: [],
      categoryFeatures: [],
      showedProviderId: null,
      mode: "Изменить",
      deleteLimit: false,
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/PRODUCTS_CATEGORY",
      DEPARTMENT: "Department/STATE",
      MEASURE: "Measure/STATE",
      PROVIDERS: "Providers/STATE",
    }),
  },
  async created() {
    await this.getDepartment(this.$root.city);
    if (!this.CATEGORY.length) {
      await this.getCategory();
    }
    if (!this.PROVIDERS.allProviders.length) {
      await this.getAllProviders();
    }
    await this.getMeasure();
    if (this.$route.params.id) {
      this.setProduct();
    } else {
      this.mode = "Добавить";
      this.input.barcodes = [""];
      this.input.images = [];
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getDepartment: "Department/GET_DEPARTMENT",
      getCategory: "Category/GET_PRODUCTS_CATEGORY",
      getProduct: "Products/GET_PRODUCT",
      addImages: "Products/ADD_IMAGES",
      addImagesByProvider: "Products/ADD_IMAGES_BY_PROVIDER",
      editProductAction: "Products/EDIT_PRODUCT",
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      getMeasure: "Measure/GET_ALL_MEASURE",
    }),
    async setProduct() {
      let response = await this.getProduct(this.$route.params.id);
      this.getCurrentProviders(response.pricesList);
      if (response.categoryIds && response.categoryIds.length) {
        this.getCategoryFeatures(response);
      }
      this.input = response;
      if (response.images.length) {
        this.bigImg = response.images[0];
      }
    },
    addBarcode() {
      this.input.barcodes.push("");
      // не следит за массивом внутри объекта
      this.$forceUpdate();
    },
    deleteBarcode(index) {
      this.input.barcodes.splice(index, 1);
      this.$forceUpdate();
    },
    async createProduct() {
      this.loading = true;
      this.input.images = [];
      let response = await api.Products.createProduct(this.input);
      if (response.type !== "error") {
        const promiseArr = [];
        // если добавили картинку загружаем ее через отдельную апи
        if (this.fileInputs.length) {
          const payload = {
            id: response.id,
            files: (() => {
              const formData = new FormData();
              this.fileInputs.forEach((img) => {
                formData.append("files", img.file);
              });
              return formData;
            })(),
          };
          let resp = this.addImages(payload);
          promiseArr.push(resp);
        }
        // если добавили лимит добавляем его через отдельную апи
        if (this.input.limit > 0 && !this.deleteLimit) {
          let resp = api.Products.addLimitToProduct({
            productId: response.id,
            limit: this.input.limit,
          });
          promiseArr.push(resp);
        }
        // вызываем апи по изменению характеристик продукта
        let respFeature = api.Features.addFeatureToProduct({
          productId: response.id,
          body: Object.values(this.productFeatures).reduce(
            (accum, arr) => accum.concat(arr),
            []
          ),
        });
        promiseArr.push(respFeature);
        await Promise.allSettled(promiseArr);
        ShowMessage("Продукт успешно создан", true);
        this.$router.go(-1);
      } else {
        ShowMessage(response.data.message);
      }
      this.loading = false;
    },
    async editProduct() {
      this.loading = true;
      this.input.deleted = false;
      this.input.images = [];
      let response = await this.editProductAction(this.input);
      if (response.type !== "error") {
        const promiseArr = [];
        // если добавили картинку загружаем ее через отдельную апи
        if (this.fileInputs.length) {
          const payload = {
            id: this.input.providerProductId,
            files: (() => {
              const formData = new FormData();
              this.fileInputs.forEach((img) => {
                formData.append("files", img.file);
              });
              return formData;
            })(),
          };
          let resp;
          if (this.$root.isProvider) {
            resp = this.addImagesByProvider(payload);
          } else {
            resp = this.addImages(payload);
          }
          promiseArr.push(resp);
        }
        // Если есть фотки для удаления
        if (this.deleteImgs.length) {
          const req = {
            productId: this.input.providerProductId,
          };
          this.deleteImgs.forEach((id) => {
            req.imageId = id;
            api.Products.deleteProductImage(req);
          });
        }
        // если добавили лимит добавляем его через отдельную апи
        if (this.input.limit > 0 && !this.deleteLimit) {
          let resp = api.Products.addLimitToProduct({
            productId: response.id,
            limit: this.input.limit,
          });
          promiseArr.push(resp);
        }
        // вызываем апи по изменению характеристик продукта
        let respFeature = api.Features.addFeatureToProduct({
          productId: response.id,
          body: Object.values(this.productFeatures).reduce(
            (accum, arr) => accum.concat(arr),
            []
          ),
        });
        promiseArr.push(respFeature);

        if (this.deleteLimit) {
          let resp = api.Products.removeLimitFromProduct(response.id);
          promiseArr.push(resp);
        }
        await Promise.allSettled(promiseArr);
        ShowMessage("Продукт успешно изменен", true);
        this.$router.go(-1);
      } else {
        ShowMessage(response.data.message);
      }
      this.loading = false;
    },
    setImage(images) {
      images.forEach((img) => {
        if (img.size > 500000) {
          ShowMessage("Размер файла превышает 500Kb");
          return;
        } else {
          // тут храним чистые изображения для сервера
          const id = Math.random();
          this.fileInputs.push({ file: img, id });
          this.input.images.push({
            fileUrl: URL.createObjectURL(img),
            id,
            onSiteOnly: true,
          });
          if (!this.bigImg) {
            this.bigImg = this.input.images[0];
          }
          // не следит за массивом внутри объекта
          this.$forceUpdate();
        }
      });
    },
    deleteImg(image) {
      this.input.images = this.input.images.filter(
        (img) => img.id !== image.id
      );
      if (this.input.images.length) {
        this.bigImg = this.input.images[0];
      } else {
        this.bigImg = null;
      }
      this.fileInputs = this.fileInputs.filter((img) => img.id !== image.id);
      if (!image.onSiteOnly) {
        this.deleteImgs.push(image.id);
      }
    },

    setFeatures() {
      this.getCategoryFeatures({
        categoryIds: this.input.categoryIds,
        features: [],
      });
    },

    async getCategoryFeatures(response) {
      let features = await api.Features.getCategoryFeatures(
        // добавляем нулевую категорию еще для основных характеристик
        [...response.categoryIds].concat([0])
      );
      this.categoryFeatures = features;
      // Создаем объект с массивами уже существующих характеристик продукта
      this.productFeatures = response.features.reduce((accum, item) => {
        accum[item.featureName] = item.featureValue.map((val) => val.valueId);
        return accum;
      }, {});
      // добавляем в него пустые массивы всех возможных характеристик
      this.productFeatures = features.reduce(
        (accum, item) => {
          if (!accum[item.featureName]) {
            accum[item.featureName] = [];
          }
          return accum;
        },
        { ...this.productFeatures }
      );
    },

    // находит нужных поставщиков на основании объекта с ценами у продукта
    getCurrentProviders(pricesList) {
      let current = this.PROVIDERS.allProviders.filter((provider) =>
        pricesList.map((item) => item.providerId).includes(provider.id)
      );
      if (current.length) {
        this.currentProviders = current;
        this.showedProviderId = current[0].id;
      }
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.main {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0px;
  &_data {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  &_images {
    margin-top: 10px;
    width: 33%;
  }
  &_text {
    color: black;
    font-weight: 700;
  }
  &_hint {
    font-size: 12px;
    margin: -20px 0 0 !important;
  }
  &_img-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    &_data {
      width: 100%;
    }
    &_images {
      width: 100%;
    }
  }
}

.title {
  font-size: 20px;
  color: black;
}
.feature {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
  &_box {
    display: flex;
    align-items: flex-end;
    width: 45%;
    @media screen and (max-width: 1050px) {
      width: 100%;
    }
  }
  &_select {
    max-width: 200px;
    min-width: 200px;
    border-bottom: 2px solid #51c8f0;
  }
  &_text {
    min-width: max-content;
    color: black;
    font-weight: bold;
  }
  &_under {
    width: 80%;
    border-bottom: #51c8f0 2px dotted;
  }
}
.barcode {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  &_item {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 90%;
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
}
.text-input {
  min-width: 200px;
  max-width: 300px;
}
.input-close {
  max-width: 200px;
  background-color: #e93e7b;
  border-radius: 20px;
  .v-input__slot {
    padding-left: 10px !important;
    color: white !important;
  }
  input {
    color: white !important;
  }
}
.table-header {
  th {
    color: #5d4bd0 !important;
    font-weight: bold !important;
  }
}
.table-btns {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: black !important;
}
.image-box {
  min-width: 80%;
  position: relative;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  &_btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.conteiner {
  display: flex;
  gap: 20px;
  padding-right: 10px;
}
.images {
  &_vertical {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    gap: 5px;
    scroll-behavior: smooth;
    @media screen and (max-width: 600px) {
      height: 250px;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &_small {
    width: 90px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    &_img {
      display: flex;
      justify-content: center;
      padding: 3px;
      border-radius: 12px;
      min-width: 70px;
      min-height: 70px;
      overflow: hidden;
      border: 1px solid #7c7a7a;
      &:hover {
        cursor: pointer;
      }
      @media screen and (max-width: 700px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}
.selected {
  border: 1px solid #e93e7b;
}
.thumbnail {
  max-width: 100%;
  height: 60px;
  border-radius: 7px;
  user-select: none;
  object-fit: cover;
}
.table-providers {
  width: 70%;
}
</style>
